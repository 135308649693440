import { AuthError } from '@/pages/auth/types';

export interface AuthenticationFormData {
  code: string;
}

export interface AuthenticationPayload {
  code: string;
  mfaToken: string;
  recaptchaToken: string;
}

export interface SendOtpError extends AuthError {
  resendAllowedAt?: string;
}

export enum MfaErrorCode {
  INVALID_CODE = 'invalid_code',
  FAILED = 'failed',
  INVALID_MFA_TOKEN = 'invalid_mfa_token',
}

export enum SendEmailOtpErrorCode {
  UNSUPPORTED = 'unsupported',
  INVALID_MFA_TOKEN = 'invalid_mfa_token',
  LOCKED_ACCOUNT = 'locked_account',
  NOT_RESEND = 'not_resend',
  INVALID_CREDENTIALS = 'invalid_credentials',
}
