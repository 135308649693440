export interface LoginFormData {
  email: string;
  password: string;
}

export interface LoginPayload {
  recaptchaToken: string;
  email: string;
  password: string;
}

// Login Error Data
export enum LoginErrorCode {
  MFA_REQUIRED = 'mfa_required',
  FAILED = 'failed',
  INVALID_CREDENTIALS = 'invalid_credentials',
  LOCKED_ACCOUNT = 'locked_account',
}
