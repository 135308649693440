import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useGateways } from '@/contexts/gateways';
import { ResetPasswordParams } from '@/core/models/User';

export default function useResetPasswordMutation() {
  const { authGateway } = useGateways();
  return useMutation({
    mutationFn: (params: ResetPasswordParams) => authGateway.resetPassword(params),
    onError: (error: AxiosError<any>) => error,
  });
}
