import axios from 'axios';
import Cookies from 'js-cookie';

import { toastInfo } from '@/components/Toastify';
import {
  FormData,
  LoginParams,
  ResetPasswordParams,
  Step,
  StepResponse,
  UserData,
} from '@/core/models/User';
import AuthGateway from '@/ports/auth';

import { mapAddressToApi, mapPaymentToApi, mapUserToApi } from './mappers/formDataMapper';

export default function createApiAuthGateway(): AuthGateway {
  const _urlParams = new URLSearchParams(window.location.search);
  const _clientId = _urlParams.get('client_id');
  const _redirectUri = _urlParams.get('redirect_uri');
  const _scope = _urlParams.get('scope')?.replace(',', ' ');
  const _state = _urlParams.get('state');
  const credentialsEnv = import.meta.env.VITE_APP_CREDENTIALS;

  async function fetchUserData(): Promise<UserData> {
    const {
      data: { application, meta },
    } = await axios.post(`/oauth2/applications/${_clientId}/verify`, {
      redirect_uri: _redirectUri,
      scope: _scope,
    });

    const details: UserData = {
      platform: {
        name: application.name,
        logo: application.logo_url,
      },
    };

    if (meta.user) {
      details.name = `${meta.user.first_name} ${meta.user.last_name}`;
      details.email = meta.user.email;
    }

    if (meta.company) {
      details.companyName = meta.company.name;
      details.country = meta.company.country_id;
    }

    return details;
  }

  async function fetchSteps(): Promise<Step[]> {
    const {
      data: { steps },
    } = await axios.get(`/oauth2/applications/${_clientId}/steps`);
    return steps
      .map((step: StepResponse) => {
        return {
          template: step.template,
          id: step.id,
          order: step.order,
          required: step.required,
        };
      })
      .sort((a: Step, b: Step) => (a.order > b.order ? 1 : -1));
  }

  function removeCredentials(): void {
    const cookieDomain = window.location.hostname === 'localhost' ? 'localhost' : '.easyship.com';
    Cookies.remove(credentialsEnv, {
      secure: true,
      sameSite: 'none',
      partitioned: true,
      domain: cookieDomain,
      path: '/',
    });
  }

  function setCredentialsCookies(token: string): void {
    if (Cookies.get(credentialsEnv)) removeCredentials();

    const cookieDomain = window.location.hostname === 'localhost' ? 'localhost' : '.easyship.com';

    Cookies.set(credentialsEnv, token, {
      secure: true,
      sameSite: 'none',
      partitioned: true,
      domain: cookieDomain,
      path: '/',
    });
  }

  function resetPassword({ email, token }: ResetPasswordParams): Promise<undefined> {
    return axios.patch(
      '/api/v1/users/password_reset',
      { email, token },
      { headers: { 'recaptcha-token': token } },
    );
  }

  async function login({ user, token }: LoginParams): Promise<UserData> {
    const config = {
      headers: { 'recaptcha-token': token },
      ignoreAuthModule: true,
    };

    const { data } = await axios.post('/api/v1/sessions', { user, token }, config);
    const isAdmin = data.user && data.user.role && data.user.role.scope !== 'company';
    if (isAdmin) throw new Error();

    setCredentialsCookies(data.session.session_token);
    return fetchUserData();
  }

  async function signUp(data: FormData): Promise<string> {
    const {
      data: {
        user: {
          session: { token },
        },
      },
    } = await axios.post(`/oauth2/applications/${_clientId}/enrolls`, {
      steps: [
        {
          id: data.user.id,
          form_elements: mapUserToApi(data.user),
        },
        {
          id: data.address.id,
          form_elements: mapAddressToApi(data.address),
        },
        {
          id: data.payment.id,
          form_elements: mapPaymentToApi(data.payment),
        },
      ],
    });
    setCredentialsCookies(token);
    const url = await authorize();
    return url;
  }

  async function authorize(): Promise<string> {
    const {
      data: { status, redirect_uri },
    } = await axios.post('/oauth2/authorize', {
      client_id: _clientId,
      redirect_uri: _redirectUri,
      scope: _scope,
      response_mode: 'query',
      response_type: 'code',
      state: _state,
    });
    return status === 'redirect' ? redirect_uri : '';
  }

  return {
    fetchUserData,
    fetchSteps,
    signUp,
    login,
    resetPassword,
  };
}
