import { useMutation } from '@tanstack/react-query';

import { AuthenticationPayload } from '@/pages/auth/authentication/types';
import { useAuthGateways } from '@/pages/auth/contexts/AuthGatewayProviders';

export default function useVerifyAuthenticateMutation() {
  const { authGateway } = useAuthGateways();

  return useMutation({
    mutationFn: (payload: AuthenticationPayload) => authGateway.verifyAuthenticate(payload),
  });
}
