import { AxiosError } from 'axios';
import { Alert, Button } from 'easyship-components';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { ControlledInput } from '@/components/ControlledInput';
import { toastError } from '@/components/Toastify';
import { SIGNUP_LOGIN_EMAIL_REGEX } from '@/core/regex';
import useRecaptcha from '@/hooks/useRecaptcha';
import useLoginMutation from '@/pages/auth/login/hooks/useLoginMutation';
import { LoginErrorCode, LoginFormData, LoginPayload } from '@/pages/auth/login/types';
import { AuthError, AuthErrorData } from '@/pages/auth/types';
import { parse } from '@/pages/auth/utils/errorParser';
import { LocalStorageExpiry } from '@/pages/auth/utils/LocalStorageExpiry';

const IS_DEVELOPMENT = import.meta.env.VITE_ENV_NAME !== 'production';

export const LoginForm = () => {
  const { recaptchaToken, fetchAndSetRecaptcha } = useRecaptcha();
  const { control, handleSubmit, getValues } = useForm<LoginFormData>();
  const navigate = useNavigate();
  const credentialsEnv = import.meta.env.VITE_APP_CREDENTIALS;

  const localStorage = new LocalStorageExpiry();

  const { state } = useLocation();

  const [error, setError] = useState(() => {
    return state?.error ? state.error : '';
  });

  useEffect(() => {
    const handleUnload = () => {
      setError('');
      window.history.replaceState({}, '');
    };
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  const handleError = (data: AuthError) => {
    // make sure that the recapatcha is fetched
    fetchAndSetRecaptcha();
    if (data.code === LoginErrorCode.MFA_REQUIRED) {
      const email = getValues('email');
      // set to localStorage to prevent <ResendOtpButton /> sending request to BE
      if (data.mfa?.emailOtp) {
        localStorage.set('resendAllowedAt', data.mfa.emailOtp.resendAllowedAt);
        localStorage.set('expiresAt', data.mfa.emailOtp.expiresAt);
        Cookies.set('mfaToken', data.mfa.token, {
          domain: window.location.hostname === 'localhost' ? 'localhost' : '.easyship.com',
          secure: true,
          sameSite: 'none',
          partitioned: true,
          path: '/',
        });
      }
      navigate('/auth/authenticate', {
        state: {
          email: maskEmail(email),
          mfaToken: data.mfa?.token,
          expiresAt: data.mfa?.emailOtp.expiresAt,
          resendAllowedAt: data.mfa?.emailOtp.resendAllowedAt,
        },
      });
    } else if (
      data.code === LoginErrorCode.INVALID_CREDENTIALS ||
      data.code === LoginErrorCode.FAILED ||
      data.code === LoginErrorCode.LOCKED_ACCOUNT
    ) {
      setError(data.message);
    } else {
      toastError();
    }
  };

  const { mutateAsync: loginMutateAsync, isPending: isLoading } = useLoginMutation();

  const onSubmit: SubmitHandler<LoginFormData> = async ({ email, password }: LoginFormData) => {
    try {
      const token = await fetchAndSetRecaptcha();

      const payload: LoginPayload = {
        email: email.trim(),
        password,
        recaptchaToken: token ?? recaptchaToken,
      };

      const data = await loginMutateAsync(payload);

      if (Cookies.get(credentialsEnv)) removeCredentials();
      Cookies.set(credentialsEnv, data.session.token, {
        domain: window.location.hostname === 'localhost' ? 'localhost' : '.easyship.com',
        secure: true,
        sameSite: 'none',
        partitioned: true,
        path: '/',
      });
      setTimeout(() => {
        window.location.replace(
          `https://app${IS_DEVELOPMENT ? `-${import.meta.env.VITE_ENV_NAME}` : ''}.easyship.com/dashboard`,
        );
      }, 500);
    } catch (err) {
      if (err.response?.data) {
        const error = parse(err.response.data);
        handleError(error);
      } else {
        toastError();
      }
    }
  };

  function removeCredentials(): void {
    const cookieDomain = window.location.hostname === 'localhost' ? 'localhost' : '.easyship.com';
    Cookies.remove(credentialsEnv, {
      secure: true,
      sameSite: 'none',
      partitioned: true,
      domain: cookieDomain,
      path: '/',
    });
  }

  const maskEmail = (email: string) => {
    const splitEmail = email.split('@');
    const username = splitEmail[0];
    let maskedEmail = email;
    if (username.length >= 3 && username.length <= 5) {
      maskedEmail =
        username.slice(0, 1) +
        '*'.repeat(username.length - 2) +
        username.slice(-1) +
        '@' +
        splitEmail[1];
    } else if (username.length > 5) {
      maskedEmail =
        username.slice(0, 2) +
        '*'.repeat(username.length - 4) +
        username.slice(-2) +
        '@' +
        splitEmail[1];
    }

    return maskedEmail;
  };

  return (
    <form className="flex flex-col gap-3" onSubmit={handleSubmit(onSubmit)}>
      <ControlledInput
        label="Email"
        control={control}
        name="email"
        rules={{
          required: { value: true, message: 'This field is required.' },
          pattern: { value: SIGNUP_LOGIN_EMAIL_REGEX, message: 'Please enter a valid email.' },
        }}
      />
      <ControlledInput
        label="Password"
        type="password"
        control={control}
        name="password"
        rules={{
          required: { value: true, message: 'This field is required.' },
        }}
      />
      <div>
        <Link className="text-blue-500" to="/auth/reset-password">
          Forgot your password?
        </Link>
      </div>
      {error && (
        <Alert severity="error" className="w-full">
          {error}
        </Alert>
      )}
      <Button
        onClick={handleSubmit(onSubmit)}
        color="primary"
        className="w-full"
        type="submit"
        loading={isLoading}
        disabled={isLoading}
      >
        Login
      </Button>
      <p>
        No account yet?{' '}
        <a
          className="text-blue-500 hover:text-blue-700"
          href={`https://app${IS_DEVELOPMENT ? '-staging' : ''}.easyship.com/signup`}
          rel="noreferrer"
        >
          Get started for free
        </a>
      </p>
    </form>
  );
};
