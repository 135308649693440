import { useMutation } from '@tanstack/react-query';

import { useAuthGateways } from '@/pages/auth/contexts/AuthGatewayProviders';
import { LoginPayload } from '@/pages/auth/login/types';

export default function useLoginMutation() {
  const { authGateway } = useAuthGateways();

  return useMutation({
    mutationFn: (payload: LoginPayload) => authGateway.login(payload),
  });
}
