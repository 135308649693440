import { Button } from 'easyship-components';
import Cookies from 'js-cookie';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { toastError, toastSuccess } from '@/components/Toastify';
import useSendEmailOtpMutation from '@/pages/auth/authentication/hooks/useSendEmailOtpMutation';
import { SendEmailOtpErrorCode, SendOtpError } from '@/pages/auth/authentication/types';
import { SendEmailOtpPayload } from '@/pages/auth/types';
import { LocalStorageExpiry } from '@/pages/auth/utils/LocalStorageExpiry';

interface ResendOtpButtonProps {
  recaptchaToken: string;
  onSendOtpSucceed: () => void;
  onSendOtpFailed: () => void;
}

export const ResendOtpButton = ({
  recaptchaToken,
  onSendOtpFailed,
  onSendOtpSucceed,
}: ResendOtpButtonProps) => {
  const localStorage = useMemo(() => new LocalStorageExpiry(), []);

  const navigate = useNavigate();

  const handleError = (data: SendOtpError) => {
    if (
      data.code === SendEmailOtpErrorCode.NOT_RESEND ||
      data.code === SendEmailOtpErrorCode.UNSUPPORTED
    ) {
      if (data.mfaToken) {
        Cookies.set('mfaToken', data.mfaToken, {
          domain: window.location.hostname === 'localhost' ? 'localhost' : '.easyship.com',
          secure: true,
          sameSite: 'none',
          partitioned: true,
          path: '/',
        });
      }
      toastError(data.message);
      if (data.resendAllowedAt) {
        setResendTimer(data.resendAllowedAt);
      }
    } else if (data.code === SendEmailOtpErrorCode.INVALID_MFA_TOKEN) {
      navigate('/auth/login', { state: { error: data.message } });
      toastError(data.message);
    } else if (data.code === SendEmailOtpErrorCode.INVALID_CREDENTIALS) {
      toastError('Request failed, Please try again.');
    } else {
      toastError(data.message);
    }
    onSendOtpFailed();
  };

  const { mutateAsync: sendOtpEmailMutateAsync, isPending: isSendLoading } =
    useSendEmailOtpMutation({ handleError });

  // initial resend seconds left
  const [resendSecondsLeft, setResendSecondsLeft] = useState(() => {
    const now = new Date();
    const resendAllowedAt = localStorage.get('resendAllowedAt');
    if (resendAllowedAt) {
      const timeRemaining = new Date(resendAllowedAt).getTime() - now.getTime();
      const secondsRemaining = Math.floor(timeRemaining / 1000);
      return secondsRemaining;
    }
    return 0;
  });

  // manual timer
  useEffect(() => {
    const interval = setInterval(() => {
      setResendSecondsLeft((prevState) => {
        return prevState - 1;
      });
    }, 1000);

    if (resendSecondsLeft < 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [resendSecondsLeft]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSendEmail = (resend?: boolean) => {
    const MFA_TOKEN = Cookies.get('mfaToken');

    const payload: SendEmailOtpPayload = {
      mfaToken: MFA_TOKEN ?? '',
      recaptchaToken,
    };
    sendOtpEmailMutateAsync(payload, {
      onSuccess: (res) => {
        if (resend) {
          toastSuccess(`We've sent you another verification code. Check your email.`);
        }
        localStorage.set('resendAllowedAt', res.resendAllowedAt);
        localStorage.set('expiresAt', res.expiresAt);
        Cookies.set('mfaToken', res.mfaToken, {
          domain: window.location.hostname === 'localhost' ? 'localhost' : '.easyship.com',
          secure: true,
          sameSite: 'none',
          partitioned: true,
          path: '/',
        });
        setResendTimer(res.resendAllowedAt);
        onSendOtpSucceed();
      },
    }).catch((err) => undefined);
  };

  const setResendTimer = (resendAllowedAt: string) => {
    setResendSecondsLeft(() => {
      const now = new Date();
      if (resendAllowedAt) {
        const timeRemaining = new Date(resendAllowedAt).getTime() - now.getTime();
        const secondsRemaining = Math.floor(timeRemaining / 1000);
        return secondsRemaining;
      }
      return 60;
    });
  };

  return (
    <Button
      type="button"
      onClick={() => handleSendEmail(true)}
      flat
      disabled={resendSecondsLeft >= 0 || isSendLoading}
      className="w-fit justify-start gap-0 font-bold text-blue-500 cursor-pointer hover:text-blue-700 normal-case text-[13px] hover:bg-transparent p-0 disabled:text-sky-700"
    >
      Resend Code
      {resendSecondsLeft >= 0 && <> in {resendSecondsLeft}s</>}
      {isSendLoading && (
        <div className="flex items-center justify-center">
          <svg className="w-3 h-3 ml-2 animate-spin" viewBox="0 0 24 24">
            <circle
              className="opacity-0"
              cx="13"
              cy="13"
              r="13"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </div>
      )}
    </Button>
  );
};
