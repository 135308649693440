import { useCallback, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export default function useRecaptcha() {
  const [recaptchaToken, setRecaptchaToken] = useState('');

  const { executeRecaptcha } = useGoogleReCaptcha();
  const fetchAndSetRecaptcha = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha();
    setRecaptchaToken(token);
    return token;
  }, [executeRecaptcha]);

  useEffect(() => {
    fetchAndSetRecaptcha();
  }, [fetchAndSetRecaptcha]);

  return { recaptchaToken, fetchAndSetRecaptcha };
}
