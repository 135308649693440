import { useLocation } from 'react-router-dom';

import { AuthenticationForm } from '@/pages/auth/authentication/components/AuthenticationForm';
import { AuthGatewayProvider } from '@/pages/auth/contexts/AuthGatewayProviders';

export const AuthenticationPage = () => {
  const {
    state: { email },
  } = useLocation();

  return (
    <AuthGatewayProvider>
      <p className="mt-2 font-sans text-lg font-light text-ink-500">Confirm Your Login</p>
      <div className="w-2/5 mt-8">
        <p className="mb-4 break-words break-all">
          Approve this login by entering the code we sent to <b>{email}</b>. If you can’t find it,
          check your spam.{' '}
          <a
            target="_blank"
            className="!text-blue-500 hover:text-blue-700"
            href={`${
              import.meta.env.VITE_APP_HELP
            }/hc/en-us/articles/30104448372249-Email-Verification`}
            rel="noreferrer"
          >
            Learn more
          </a>
        </p>
        <AuthenticationForm />
      </div>
    </AuthGatewayProvider>
  );
};
